import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/style/index.less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import axios from 'axios'
// import 'amfe-flexible'
// import 'lib-flexible'
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})
