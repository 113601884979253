<template>
  <div class="tabs-box">
    <div class="home-tabs-spe">
      <div class="home-tabs-spe-title">
        <i class="el-icon-s-operation" @click="drawer = true"></i>
        <img
          src="https://img.ebtech.com/%E7%BB%84%2042%402x.png"
          alt=""
          class="home-tabs-img"
        />
      </div>
      <el-drawer
        :visible.sync="drawer"
        :direction="direction"
        :append-to-body="true"
        size="100%"
      >
        <img
          class="drawer-img"
          src="https://img.ebtech.com/ebtech/hb/logo-2.png"
          alt=""
        />
        <div class="drawer-item" @click="$router.push('/')">
          <div>首页</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="drawer-item" @click="$router.push('/about')">
          <div>关于我们</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="drawer-item" @click="$router.push('/news')">
          <div>公告与新闻</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <el-menu
          background-color="#ffffff"
          text-color="#000000"
          active-text-color="#000000"
        >
          <el-submenu index="1">
            <template slot="title">
              <span class="submenu-span">北京AI创新赋能中心</span>
            </template>
            <el-menu-item-group>
              <el-menu-item @click="$router.push('/computing')"
                >智算中心｜超算算力出租</el-menu-item
              >
              <el-menu-item @click="$router.push('/resources')"
                >云资源采购平台｜高端AIDC一站式服务</el-menu-item
              >
              <el-menu-item @click="$router.push('/training')"
                >培训中心</el-menu-item
              >
              <el-menu-item @click="$router.push('/laboratory')"
                >产业实验室</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <div class="drawer-item" @click="$router.push('/activity')">
          <div>活动报名</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <!-- <div class="drawer-item" @click="$router.push('/cooperative')">
          <div>合作伙伴</div>
          <i class="el-icon-arrow-right"></i>
        </div> -->
        <div class="drawer-item" @click="$router.push('/contact')">
          <div>联系我们</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div
          class="drawer-item"
          @click="$router.push('/monitor')"
          v-if="process !== 'production'"
        >
          <div>流量分析</div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </el-drawer>
    </div>
    <div class="home-tabs">
      <img
        src="https://img.ebtech.com/%E7%BB%84%2042%402x.png"
        alt=""
        class="home-tabs-img"
      />
      <ul class="home-tabs-ul">
        <li @click="$router.push('/')">
          <a>首页</a>
        </li>
        <li @click="$router.push('/about')">
          <a>关于我们</a>
        </li>
        <li @click="$router.push('/news')">
          <a>公告与新闻</a>
        </li>
        <li
          class="home-tabs-ul-li"
          @mouseenter="show = true"
          @mouseleave="show = false"
        >
          <span>北京AI创新赋能中心</span>
          <div v-if="show" class="home-tabs-ul-li-spe">
            <div class="home-tabs-ul-li-spe-content">
              <div @click="$router.push('/computing')">
                <a>智算中心｜超算算力出租</a>
              </div>
              <div @click="$router.push('/resources')">
                <a>云资源采购平台｜高端AIDC一站式服务</a>
              </div>
              <div @click="$router.push('/training')"><a>培训中心</a></div>
              <div @click="$router.push('/laboratory')"><a>产业实验室</a></div>
            </div>
          </div>
        </li>
        <li @click="$router.push('/activity')"><a>活动报名</a></li>
        <!-- <li @click="$router.push('/cooperative')">合作伙伴</li> -->
        <li @click="$router.push('/contact')"><a>联系我们</a></li>
        <li @click="$router.push('/monitor')" v-if="process !== 'production'">
          <a>流量分析</a>
        </li>
        <li>
          <el-input
            v-if="!$route.path.includes('/search')"
            placeholder="Search"
            suffix-icon="el-icon-search"
            v-model="search"
            @change="$router.push('/search')"
            style="visibility: hidden"
          >
          </el-input>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "TabsView",
  data() {
    return {
      show: false,
      search: "",
      drawer: false,
      direction: "ltr",
      process: process.env.NODE_ENV,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.home-tabs-spe {
  display: none;
}
.home-tabs {
  height: 95px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.home-tabs-img {
  width: 106px;
  height: 40px;
  margin-right: 20px;
}
.home-tabs-ul {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
}
.home-tabs-ul li {
  margin-right: 52px;
  line-height: 95px;
  cursor: pointer;
}
.home-tabs-ul li a {
  text-decoration: none;
  position: relative;
}
.home-tabs-ul li a::after {
  content: "";
  height: 2px;
  overflow: hidden;
  display: block;
  left: 0;
  bottom: -10px;
  position: absolute;
  background: #fff;
}
.home-tabs-ul li > a::after {
  width: 100%;
  transform: scaleX(0);
  transition: all 0.5s;
}
.home-tabs-ul li > a:hover::after {
  transform: scaleX(1);
}

.home-tabs-ul li .home-tabs-ul-li-spe-content a::after {
  height: 1px;
  bottom: -5px;
  background: #8e54ed;
  width: 0;
  transition: all 0.5s ease-in-out;
}
.home-tabs-ul li .home-tabs-ul-li-spe-content a:hover::after {
  width: 100%;
}

.active {
  color: #fff;
}
.home-tabs-ul-li {
  position: relative;
}
.home-tabs-ul-li-spe {
  position: absolute;
  top: 55px;
  padding-top: 5px;
  line-height: 1;
}
.home-tabs-ul-li-spe-content {
  background: url("https://img.ebtech.com/%E7%9F%A9%E5%BD%A2%20620%402x.png");
  background-size: cover;
  color: #5e5a57;
  font-size: 12px;
  width: 242px;
  height: 178px;
  text-align: left;
  padding: 29px 0 30px 17px;
}

.home-tabs-ul-li-spe-content div {
  margin-bottom: 23px;
}
.home-tabs-ul-li-spe-content div:hover {
  color: #8e54ed;
  cursor: pointer;
}
::v-deep .el-input__inner {
  width: 341px;
  height: 41px;
  border-radius: 21px;
  background: transparent;
  color: #ffffff;
}
::v-deep .el-input__inner::placeholder {
  color: #ffffff;
}
::v-deep .el-input__suffix {
  color: #ffffff;
}

// @media screen and (min-width: 0px) and (max-width: 951px) {
// @media screen and (min-width: 0px) and (max-width: 1223px) {
@media screen and (min-width: 0px) and (max-width: 1331px) {
  .home-tabs {
    display: none;
  }
  .home-tabs-spe {
    display: block;
    height: 64px;
    background: rgba(0, 0, 0, 0.2);
  }
  .home-tabs-spe-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    padding: 10px 0;
  }
  .el-icon-s-operation {
    color: #fff;
    font-size: 24px;
  }
  .drawer-img {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 106px;
    height: 40px;
  }
  ::v-deep .el-drawer div {
    height: 54px;
    font-size: 16px;
    color: #000;
    padding: 15px;
  }
  ::v-deep .el-drawer__close-btn {
    position: absolute;
    top: 20px;
    left: 28px;
  }
  .drawer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  ::v-deep .el-menu-item-group__title {
    display: none;
  }
  ::v-deep .el-menu-item:focus {
    background: #fff !important;
  }
  // ::v-deep .el-menu-item.is-active {
  //   color: #fff;
  // }
  ::v-deep .el-submenu__title {
    padding-left: 28px !important;
    background: #fff !important;
  }
  ::v-deep .el-submenu__title i {
    color: #000;
  }
  ::v-deep .el-submenu__icon-arrow {
    font-size: 16px;
  }
  .submenu-span {
    position: absolute;
    top: 0;
  }
}
</style>
