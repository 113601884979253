<template>
  <div class="home">
    <div class="banner">
      <el-carousel indicator-position="none" class="carousel">
        <Tabs style="position: absolute; width: 100%; z-index: 99" />
        <el-carousel-item v-for="item in bannerList" :key="item.index">
          <a
            v-if="item.bannerUrl"
            :href="item.bannerUrl"
            target="_blank"
            style="width: 100%"
          >
            <div
              class="carousel-img"
              :style="{ backgroundImage: 'url(' + item.bannerImg + ')' }"
            >
              <div class="home-title">
                <div>{{ item.bannerTitle }}</div>
                <div>{{ item.subtitle }}</div>
              </div>
            </div>
          </a>
          <a v-else href="javascript:;" style="width: 100%">
            <div
              class="carousel-img"
              :style="{ backgroundImage: 'url(' + item.bannerImg + ')' }"
            >
              <div class="home-title">
                <div>{{ item.bannerTitle }}</div>
                <div>{{ item.subtitle }}</div>
              </div>
            </div>
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="container">
      <div class="container-in">
        <div class="container-news"></div>
        <div class="container-block"></div>
        <div class="container-news-title"></div>
        <Swiper></Swiper>
        <div class="container-service"></div>
        <div class="container-service-title"></div>
        <div class="container-service-in container-service-cellular">
          <div
            class="container-service-in-first special'"
            @click="$router.push('/computing')"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-img firstImg"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                智算中心｜算力出租
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">
                  由英伟达DGX系列国内顶配超算服务器和官方推荐网络传输
                  DDN存储解决方案组成的超算中心
                </div>
                <div class="container-service-in-first-b-text">
                  国内首个提供公共算力出租的
                </div>
                <div class="container-service-in-first-b-text">
                  Superpod DGX完整标准算力单元。
                </div>
              </div>
              <div class="container-button" @click="$router.push('/computing')">
                查看更多→
              </div>
            </div>
          </div>
          <div
            class="container-service-in-first special"
            @click="$router.push('/resources')"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-img"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                云资源采购平台
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">为客户提供</div>
                <div class="container-service-in-first-b-text">
                  规划、采购、建设、托管运营的
                </div>
                <div class="container-service-in-first-b-text">
                  高端AIDC一站式服务。
                </div>
              </div>
              <div class="container-button" @click="$router.push('/resources')">
                查看更多→
              </div>
            </div>
          </div>
          <div
            class="container-service-in-first special"
            @click="$router.push('/training')"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-img secondImg"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                培训中心
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">
                  积极响应《数字中国建设整体布局规划》
                </div>
                <div class="container-service-in-first-b-text">
                  培养创新型、应用型、复合型AI人才
                </div>
                <div class="container-service-in-first-b-text">
                  为产业数字化转型做好人才储备。
                </div>
              </div>
              <div class="container-button" @click="$router.push('/training')">
                查看更多→
              </div>
            </div>
          </div>
          <div
            class="container-service-in-first special"
            @click="$router.push('/laboratory')"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-img thirdImg"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                产业实验室
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">
                  以AIDC算力爆发地为抓手
                </div>
                <div class="container-service-in-first-b-text">
                  打造人工智能行业新技术应用熟化地
                </div>
                <div class="container-service-in-first-b-text">
                  独角兽及行业领军人物孕育地。
                </div>
              </div>
              <div
                class="container-button"
                @click="$router.push('/laboratory')"
              >
                查看更多→
              </div>
            </div>
          </div>
        </div>

        <div
          class="container-service-in container-service-web"
          @mouseover="handleShow"
          @mouseleave="handleCloseShow"
        >
          <div
            v-if="showFirst"
            @mouseover="showFirst = !showFirst"
            class="container-service-in-first spe"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-in-first-t-a">
                <div class="container-service-in-title">智算中心｜算力出租</div>
                <div class="container-service-in-line"></div>
              </div>
            </div>
            <div class="container-service-in-first-b">
              <div class="container-service-in-first-b-t">
                由英伟达DGX系列国内顶配超算服务器和官方推荐网络传输
                DDN存储解决方案组成的超算中心
              </div>
              <div class="container-service-in-first-b-text">
                国内首个提供公共算力出租的
              </div>
              <div class="container-service-in-first-b-text">
                Superpod DGX完整标准算力单元。
              </div>
            </div>
          </div>
          <div
            v-else
            @mouseleave="showFirst = !showFirst"
            class="container-service-in-first special'"
          >
            <div
              class="container-service-in-first-t"
              @click="$router.push('/computing')"
            >
              <div class="container-service-img firstImg"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
              @click="$router.push('/computing')"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                智算中心｜算力出租
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">
                  由英伟达DGX系列国内顶配超算服务器和官方推荐网络传输
                  DDN存储解决方案组成的超算中心
                </div>
                <div class="container-service-in-first-b-text">
                  国内首个提供公共算力出租的
                </div>
                <div class="container-service-in-first-b-text">
                  Superpod DGX完整标准算力单元。
                </div>
              </div>
              <div class="container-button" @click="$router.push('/computing')">
                查看更多→
              </div>
            </div>
          </div>

          <div
            v-if="showSecond"
            @mousemove="showSecond = !showSecond"
            class="container-service-in-first spe"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-in-first-t-a">
                <div class="container-service-in-title">云资源采购平台</div>
                <div class="container-service-in-line"></div>
              </div>
            </div>
            <div class="container-service-in-first-b">
              <div class="container-service-in-first-b-t">为客户提供</div>
              <div class="container-service-in-first-b-text">
                规划、采购、建设、托管运营的
              </div>
              <div class="container-service-in-first-b-text">
                高端AIDC一站式服务。
              </div>
            </div>
          </div>
          <div
            v-else
            @mouseleave="showSecond = !showSecond"
            class="container-service-in-first special"
          >
            <div
              class="container-service-in-first-t"
              @click="$router.push('/resources')"
            >
              <div class="container-service-img"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
              @click="$router.push('/resources')"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                云资源采购平台
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">为客户提供</div>
                <div class="container-service-in-first-b-text">
                  规划、采购、建设、托管运营的
                </div>
                <div class="container-service-in-first-b-text">
                  高端AIDC一站式服务。
                </div>
              </div>
              <div class="container-button" @click="$router.push('/resources')">
                查看更多→
              </div>
            </div>
          </div>

          <div
            v-if="showThird"
            @mouseover="showThird = !showThird"
            class="container-service-in-first spe"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-in-first-t-a">
                <div class="container-service-in-title">培训中心</div>
                <div class="container-service-in-line"></div>
              </div>
            </div>
            <div class="container-service-in-first-b">
              <div class="container-service-in-first-b-t">
                积极响应《数字中国建设整体布局规划》
              </div>
              <div class="container-service-in-first-b-text">
                培养创新型、应用型、复合型AI人才
              </div>
              <div class="container-service-in-first-b-text">
                为产业数字化转型做好人才储备。
              </div>
            </div>
          </div>
          <div
            v-else
            @mouseleave="showThird = !showThird"
            class="container-service-in-first special"
          >
            <div
              class="container-service-in-first-t"
              @click="$router.push('/training')"
            >
              <div class="container-service-img secondImg"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
              @click="$router.push('/training')"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                培训中心
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">
                  积极响应《数字中国建设整体布局规划》
                </div>
                <div class="container-service-in-first-b-text">
                  培养创新型、应用型、复合型AI人才
                </div>
                <div class="container-service-in-first-b-text">
                  为产业数字化转型做好人才储备。
                </div>
              </div>
              <div class="container-button" @click="$router.push('/training')">
                查看更多→
              </div>
            </div>
          </div>

          <div
            v-if="showFourth"
            @mouseover="showFourth = !showFourth"
            class="container-service-in-first spe"
          >
            <div class="container-service-in-first-t">
              <div class="container-service-in-first-t-a">
                <div class="container-service-in-title">产业实验室</div>
                <div class="container-service-in-line"></div>
              </div>
            </div>
            <div class="container-service-in-first-b">
              <div class="container-service-in-first-b-t">
                以AIDC算力爆发地为抓手
              </div>
              <div class="container-service-in-first-b-text">
                打造人工智能行业新技术应用熟化地
              </div>
              <div class="container-service-in-first-b-text">
                独角兽及行业领军人物孕育地。
              </div>
            </div>
          </div>
          <div
            v-else
            @mouseleave="showFourth = !showFourth"
            class="container-service-in-first special"
          >
            <div
              class="container-service-in-first-t"
              @click="$router.push('/laboratory')"
            >
              <div class="container-service-img thirdImg"></div>
            </div>
            <div
              class="container-service-in-first-b container-service-in-first-b-spe"
              @click="$router.push('/laboratory')"
            >
              <div
                class="container-service-in-title container-service-in-title-spe"
              >
                产业实验室
              </div>
              <div class="container-service-in-line"></div>
              <div class="container-service-in-first-b-box">
                <div class="container-service-in-first-b-t">
                  以AIDC算力爆发地为抓手
                </div>
                <div class="container-service-in-first-b-text">
                  打造人工智能行业新技术应用熟化地
                </div>
                <div class="container-service-in-first-b-text">
                  独角兽及行业领军人物孕育地。
                </div>
              </div>
              <div
                class="container-button"
                @click="$router.push('/laboratory')"
              >
                查看更多→
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="container-from"></div> -->
        <div class="container-from-in">
          <div class="container-from-img"></div>
          <div class="container-from-text">
            <div class="container-from-beijing"></div>
            <div class="container-in-b-imgs"></div>
            <div class="container-from-innovation"></div>
            <div>英博数科作为鸿博股份 (002229) 全资子公司</div>
            <div>也是北京·AI创新赋能中心唯一指定运营主体</div>
            <div class="container-from-text-spe">
              以”汇聚全球顶尖科技，助力中国产业数字化转型"为使命
            </div>
            <div>定位于面向市场为专业AI算力需求和大模型训练提供算力服务、</div>
            <div>架构及落地场景解决方案的服务运营商。</div>
          </div>
        </div>
      </div>
      <!-- <div class="content">
        <div class="content-in">
          <div class="content-in-left">
            <el-carousel
              :autoplay="false"
              class="content-in-left-t"
              indicator-position="none"
              arrow="never"
              ref="carousel"
            >
              <el-carousel-item v-for="item in list" :key="item.index">
                <div class="content-in-text">
                  {{ item.targetDesc }}
                </div>
              </el-carousel-item>
            </el-carousel>
            <el-carousel
              :autoplay="false"
              class="content-in-left-b"
              indicator-position="none"
              arrow="never"
              ref="Scarousel"
              style="width: 264px; margin: 0 auto"
            >
              <el-carousel-item v-for="item in list" :key="item.index">
                <div
                  class="content-in-img"
                  :style="{ backgroundImage: 'url(' + item.targetImg + ')' }"
                ></div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="content-in-right">
          <div class="content-in-e"></div>
          <div class="content-in-t"></div>
          <div class="content-in-content">
            汇聚全球顶尖科技，助力中国数字化转型。
          </div>
          <div class="content-in-arrow">
            <div class="content-in-l" @click="handleLeftClick"></div>
            <div class="content-in-r" @click="handleRightClick"></div>
          </div>
        </div>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Tabs from "@/components/tabs.vue";
import Footer from "@/components/footer.vue";
import Swiper from "@/components/swiper.vue";

import axios from "axios";
export default {
  name: "HomeView",
  components: {
    Tabs,
    Footer,
    Swiper,
  },
  data() {
    return {
      search: "",
      showFirst: true,
      showSecond: false,
      showThird: true,
      showFourth: true,
      show: true,
      list: [],
      bannerList: [],
    };
  },
  mounted() {
    axios
      .get(`/portal/banner/list`, {
        params: {
          bannerPageType: 0,
          currentPage: 1,
          size: 10,
        },
      })
      .then((res) => {
        this.bannerList = res.data.data.list;
      });
    axios
      .get(`/portal/target/list`, {
        params: {
          currentPage: 1,
          size: 10,
        },
      })
      .then((res) => {
        this.list = res.data.data.list;
      });
  },
  computed: {},
  methods: {
    handleShow() {
      if (
        this.showFirst == false ||
        this.showThird == false ||
        this.showFourth == false
      ) {
        this.showSecond = true;
      }
    },
    handleCloseShow() {
      this.showSecond = false;
    },
    handleLeftClick() {
      this.$refs.carousel.prev();
      this.$refs.Scarousel.prev();
    },
    handleRightClick() {
      this.$refs.carousel.next();
      this.$refs.Scarousel.next();
    },
  },
};
</script>

<style scoped lang="less">
.banner {
  position: relative;
  height: 820px;
  background-size: cover;
  background-position: center;
}
.carousel {
  position: relative;
}
.carousel-img {
  width: 100%;
  height: 820px;
  background-size: cover;
  background-position: center;
}
::v-deep .carousel .el-carousel__container {
  height: 820px;
}
.home-title {
  position: absolute;
  bottom: 218px;
  width: auto;
  height: 180px;
  background: url("https://img.ebtech.com/%E7%9F%A9%E5%BD%A2%20843%402x.png");
  font-size: 50px;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  padding-top: 14px;
  padding-left: 104px;
  padding-right: 120px;
}
.container {
  // background: linear-gradient(
  //   0deg,
  //   rgba(255, 238, 247, 0.8) 0%,
  //   rgba(255, 238, 247, 0) 100%
  // );
  padding: 99px 0 0;
}
.container-in {
  width: 1310px;
  margin: 0 auto;
}
.container-from {
  width: 215px;
  height: 21px;
  background: url("https://img.ebtech.com/From%20China%402x.png");
  background-size: cover;
  margin-bottom: 58px;
}
.container-from-in {
  display: flex;
  align-items: center;
  margin-bottom: 72px;
}
.container-from-img {
  width: 901px;
  height: 661px;
  background: url("https://img.ebtech.com/%E4%B8%BB%E9%A1%B5%E4%B8%8B%E6%96%B9%E5%9B%BE%E7%89%87.jpg");
  background-size: cover;
}
.container-from-text {
  width: 460px;
  margin-left: 86px;
  text-align: left;
  font-size: 16px;
}
.container-from-beijing {
  width: 186px;
  height: 87px;
  background: url("https://img.ebtech.com/%E8%8B%B1%E5%8D%9A%E6%95%B0%E7%A7%91-%E4%B8%AD%E8%8B%B1%E6%96%87-%E7%AB%96-%E9%BB%91.png");
  background-size: cover;
}
.container-from-innovation {
  width: 81px;
  height: 7px;
  background: url("https://img.ebtech.com/%E7%9F%A9%E5%BD%A2%20845%402x.png");
  background-size: cover;
  margin: 34px 0 60px;
}
.container-from-text-spe {
  margin-top: 25px;
}
.container-service {
  width: 177px;
  height: 12px;
  background: url("https://img.ebtech.com/Service%20module%402x.png");
  background-size: cover;
  margin: 96px 0 13px;
}
.container-service-title {
  width: 123px;
  height: 29px;
  background: url("https://img.ebtech.com/%E4%B8%9A%E5%8A%A1%E6%A8%A1%E5%9D%97%402x.png");
  background-size: cover;
}

.container-service-in {
  display: flex;
}
.container-service-cellular {
  display: none;
}
.container-service-in-first {
  width: 328px;
  height: 656px;
  margin: 63px 0px 70px 0;
}
.container-service-in-first-t {
  position: relative;
  width: 328px;
  height: 328px;
  border-bottom: 2px solid #a6a6a6;
}
.container-service-img {
  width: 328px;
  height: 328px;
  background: url("https://img.ebtech.com/marius-masalar-CyFBmFEsytU-unsplash%20%E6%8B%B7%E8%B4%9D%402x.png")
    center no-repeat;
  background-size: cover;
}
.container-service-img.firstImg {
  background: url("https://img.ebtech.com/%E5%88%87%E5%9B%BE%402x.png") center
    no-repeat;
  background-size: cover;
}
.container-service-img.secondImg {
  background: url("https://img.ebtech.com/charlesdeluvio-Lks7vei-eAg-unsplash%402x%20%282%29.png")
    center no-repeat;
  background-size: cover;
}
.container-service-img.thirdImg {
  background: url("https://img.ebtech.com/6032248%402x.png") center no-repeat;
  background-size: cover;
}

.container-service-in-first-t-a {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 6%);
}
.container-service-in-title {
  width: 219px;
  font-weight: bold;
  font-size: 22px;
  color: #000000;
  padding-bottom: 18px;
  margin: 0 auto;
}
.container-service-in-title-spe {
  padding-top: 26px;
}
.container-service-in-line {
  width: 34px;
  height: 3px;
  background: #a6a6a6;
  margin: 0 auto;
}
.container-service-in-first-b-spe {
  background: #f5f8f7;
  box-shadow: 2px 3px 22px 0px rgba(0, 0, 0, 0.21);
  padding-bottom: 33px;
}
.container-service-in-first-b-box {
  height: 78px;
}
.container-service-in-first-b-t {
  font-size: 13px;
  color: #5e5e5e;
  margin: 27px 0 22px;
}
.container-service-in-first-b-text {
  width: 244px;
  margin: 0 auto;
  font-size: 11px;
  color: #5e5e5e;
}
.container-button {
  width: 113px;
  height: 42px;
  background: #6ee4cc;
  border-radius: 5px;
  color: #ffffff;
  line-height: 42px;
  margin: 23px auto;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.container-news {
  width: 285px;
  height: 46px;
  background: url("https://img.ebtech.com/Announcements%20and%20news%402x.png");
  background-size: cover;
}
.container-block {
  width: 37px;
  height: 3px;
  background: url("https://img.ebtech.com/%E5%85%AC%E5%91%8A%E4%B8%8E%E6%96%B0%E9%97%BB-%E5%A4%B9%402x.png");
  background-size: cover;
  margin: 10px 0 10px;
}
.container-news-title {
  width: 80px;
  height: 16px;
  background: url("https://img.ebtech.com/%E5%85%AC%E5%91%8A%E4%B8%8E%E6%96%B0%E9%97%BB%402x.png")
    no-repeat;
  background-size: contain;
  margin-bottom: 55px;
}
::v-deep .el-carousel {
  margin-bottom: 65px;
}
::v-deep .el-carousel__item {
  display: flex;
}

::v-deep .el-carousel__indicators--outside {
  display: none;
}

.content {
  display: flex;
  align-items: center;
  width: 1440px;
  margin: 0 auto;
}
.content-in {
  position: relative;
  width: 935px;
  height: 850px;
  background: url("https://img.ebtech.com/Shape%20572%20copy%203%402x.png");
  background-size: cover;
  margin-top: 72px;
}
.content-in-left {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .content-in-left .el-carousel {
  width: 454px;
  margin: 0 auto;
}
.content-in-text {
  text-align: left;
  font-size: 16px;
}
.content-in-left-b {
  position: absolute;
  left: 210px;
}
.content-in-img {
  width: 264px;
  height: 49px;
  // background: url("@/assets/图层\ 632@2x.png");
  background-size: cover;
}

.content-in-e {
  width: 132px;
  height: 15px;
  background: url("https://img.ebtech.com/Our%20goal%402x.png");
  background-size: cover;
}
.content-in-t {
  width: 184px;
  height: 35px;
  background: url("https://img.ebtech.com/%E6%88%91%E4%BB%AC%E7%9A%84%E7%9B%AE%E6%A0%87%402x.png");
  background-size: cover;
  margin: 12px 0 84px;
}
.content-in-content {
  color: #525f7f;
  font-size: 18px;
}
.content-in-right {
  height: 400px;
}
.content-in-arrow {
  display: flex;
  margin-top: 92px;
  cursor: pointer;
}
.content-in-l {
  width: 29px;
  height: 22px;
  background: url("https://img.ebtech.com/%EF%BC%9E%402x.png");
  background-size: cover;
  margin-right: 46px;
}
.content-in-l:hover {
  background: url("https://img.ebtech.com/%EF%BC%9E%20%E6%8B%B7%E8%B4%9D%402x.png");
  background-size: cover;
}
.content-in-r {
  width: 90px;
  height: 22px;
  background: url("https://img.ebtech.com/%EF%BC%9C%20%E6%8B%B7%E8%B4%9D%402x.png");
  background-size: cover;
}
.content-in-r:hover {
  background: url("https://img.ebtech.com/%EF%BC%9C%402x.png");
  background-size: cover;
}

.container-in-b-imgs {
  display: none;
}

/*----------------------------------平板样式---------------------------------------*/
@media screen and (min-width: 951px) and (max-width: 1420px) {
  ::v-deep .home-tabs-ul li:nth-child(8n) {
    display: none;
  }

  .container {
    width: 100%;
    overflow: hidden;
    padding-top: 50px;
  }
  .container-in {
    width: 100%;
  }
  .container-service-in-first {
    width: 25vw;
    height: 50vw;
    margin: 63px 0px 120px 0px;
  }
  .container-service-in-first-t {
    position: relative;
    width: 25vw;
    height: 25vw;
    border-bottom: 2px solid #a6a6a6;
  }
  .container-service-img {
    width: 25vw;
    height: 25vw;
    background-size: cover;
  }

  .container-service-in-title {
    padding-top: 16px;
  }
  .container-service-in-first-b-t {
    margin: 15px 0 15px;
  }
  .container-service-in-first-b-box {
    height: 100px;
  }
  .container-service-in-first-b-spe {
    padding-bottom: 20px;
  }

  .container-news {
    margin-left: 10px;
  }
  .container-block {
    margin-left: 10px;
  }
  .container-news-title {
    margin-left: 10px;
  }

  .container-service {
    margin-left: 10px;
    margin-top: 50px;
  }

  .container-service-title {
    margin-left: 10px;
  }

  .container-from-img {
    width: 68vw;
    height: 50vw;
  }
  .container-from-text {
    margin-right: 6vw;
    width: 35vw;
  }
}

@media screen and (min-width: 0px) and (max-width: 951px) {
  // @media screen and (min-width: 951px) and (max-width: 1400px) {
  .banner {
    height: 380px;
  }
  .container {
    padding: 20px 0 0;
  }
  .carousel-img {
    width: auto;
    height: 380px;
    background-position: 747px 0px;
  }
  ::v-deep .carousel .el-carousel__container {
    height: 380px;
  }
  .home-title {
    bottom: 60px;
    width: 390px;
    height: auto;
    padding: 15px 0 15px 25px;
    font-size: 18px;
    left: 0;
    width: auto;
    padding-right: 30px;
  }
  .container-in {
    width: 100%;
    overflow: hidden;
  }
  .container-news {
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 10px;
  }
  .container-block {
    margin-left: 10px;
  }
  .container-news-title {
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .container-service {
    margin-top: 20px;
    margin-left: 10px;
  }
  .container-service-title {
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 10px;
  }
  .container-service-in {
    display: block;
  }
  .container-service-web {
    display: none;
  }
  .container-from-in {
    display: block;
    margin-bottom: 20px;
  }
  .container-from-img {
    width: 100vw;
    height: 73vw;
  }
  .container-from-text {
    width: 90%;
    margin: 20px auto;
    text-align: center;
  }
  .container-from-innovation {
    margin: 20px 0;
    display: none;
  }

  .container-from-beijing {
    display: none;
  }

  .container-in-b-imgs {
    width: 208px;
    height: 64px;
    background: url("https://img.ebtech.com/%E8%8B%B1%E5%8D%9A%E6%95%B0%E7%A7%91-%E4%B8%AD%E8%8B%B1%E6%96%87-%E7%AB%96-%E9%BB%91.png")
      center no-repeat;
    background-size: contain;
    margin: 25px auto 30px;
    display: block;
  }
  .container-service-img {
    border-radius: 10px 0 0 10px;
  }
}

/*-----------------------------------小屏幕---------------------------------*/
@media screen and (min-width: 0px) and (max-width: 420px) {
  .container-service-in-first {
    width: 90%;
    margin: 40px auto;
    height: 52vw;
    display: flex;
  }
  .container-service-in-first-b {
    padding: 0 10px;
    width: 50vw;
  }

  .container-service-in-first-t {
    width: 40vw;
    height: 52vw;
    border: none;
  }
  .container-service-in-first-b-t {
    margin: 5px auto 5px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .container-service-img {
    width: 40vw;
    height: 52vw;
  }
  .container-service-in-title-spe {
    font-size: 18px;
    width: auto;
    text-align: center;
    margin-top: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .container-service-in-first-b-text {
    width: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .container-service-in-line {
    height: 2px;
  }
  .container-button {
    margin: 10px auto 0;
    height: 32px;

    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 421px) and (max-width: 520px) {
  .container-service-in-first {
    width: 90%;
    margin: 40px auto;
    height: 52vw;
    display: flex;
  }
  .container-service-in-first-b {
    padding: 0 10px;
    width: 50vw;
  }

  .container-service-in-first-t {
    width: 40vw;
    height: 52vw;
    border: none;
  }
  .container-service-in-first-b-t {
    margin: 15px auto 15px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .container-service-img {
    width: 40vw;
    height: 52vw;
  }
  .container-service-in-title-spe {
    font-size: 18px;
    width: auto;
    text-align: center;
    margin-top: 17px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .container-service-in-first-b-text {
    width: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .container-service-in-line {
    height: 2px;
  }
  .container-button {
    margin: 20px auto 0;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
}

/*-----------------------------------小屏幕2---------------------------------*/
@media screen and (min-width: 521px) and (max-width: 700px) {
  .banner {
    height: 500px;
  }
  .carousel-img {
    width: auto;
    height: 500px;
    background-position: 970px 0px;
  }
  ::v-deep .carousel .el-carousel__container {
    height: 500px;
  }
  .home-title {
    bottom: 100px;
    width: 390px;
    height: auto;
    padding: 15px 0 15px 25px;
    font-size: 18px;
    left: 0;
    width: auto;
    padding-right: 30px;
  }

  .container-service-in-first {
    width: 90%;
    margin: 40px auto;
    height: 40vw;
    display: flex;
  }
  .container-service-in-first-b {
    padding: 0 10px;
    width: 50vw;
  }

  .container-service-in-first-t {
    width: 40vw;
    height: 40vw;
    border: none;
  }
  .container-service-in-first-b-t {
    margin: 5px auto 5px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .container-service-img {
    width: 40vw;
    height: 40vw;
  }
  .container-service-in-title-spe {
    font-size: 18px;
    width: auto;
    text-align: center;
    margin-top: 15px;
    padding-top: 7px;
    padding-bottom: 10px;
  }

  .container-service-in-first-b-text {
    width: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin: 10px auto;
  }
  .container-service-in-line {
    height: 2px;
  }
  .container-button {
    margin: 10px auto 0;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  .container-service-in-first-b-box {
    height: auto;
  }
}
/*-----------------------------------小屏幕3---------------------------------*/
@media screen and (min-width: 701px) and (max-width: 951px) {
  .banner {
    height: 820px;
  }
  .carousel-img {
    width: auto;
    height: 820px;
    background-position: 1640px 0px;
  }
  ::v-deep .carousel .el-carousel__container {
    height: 820px;
  }
  .home-title {
    bottom: 140px;
    width: 390px;
    height: auto;
    padding: 15px 0 15px 25px;
    font-size: 18px;
    left: 0;
    width: auto;
    padding-right: 30px;
  }

  .container-service-in-first {
    width: 90%;
    margin: 40px auto;
    height: 40vw;
    display: flex;
  }
  .container-service-in-first-b {
    padding: 0 10px;
    width: 50vw;
  }

  .container-service-in-first-t {
    width: 40vw;
    height: 40vw;
    border: none;
  }
  .container-service-in-first-b-t {
    margin: 15px auto 15px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    padding: 0 30px;
  }
  .container-service-img {
    width: 40vw;
    height: 40vw;
  }
  .container-service-in-title-spe {
    font-size: 24px;
    width: auto;
    text-align: center;
    margin-top: 40px;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .container-service-in-first-b-text {
    width: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin: 20px auto;
  }
  .container-service-in-line {
    height: 2px;
  }
  .container-button {
    margin: 10px auto 0;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    width: 150px;
  }
  .container-service-in-first-b-box {
    height: auto;
  }
}

/*----------------------------------大屏样式---------------------------------------*/
@media screen and (min-width: 1900px) and (max-width: 2560px) {
  .banner {
    height: 930px;
  }
  .carousel {
    position: relative;
  }
  .carousel-img {
    width: 100%;
    height: 930px;
    background-size: cover;
    background-position: center;
  }
  ::v-deep .carousel .el-carousel__container {
    height: 930px;
  }
  .home-title {
    position: absolute;
    bottom: 208px;
    width: auto;
    height: 180px;
    background: url("https://img.ebtech.com/%E7%9F%A9%E5%BD%A2%20843%402x.png");
    font-size: 50px;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
    padding-top: 14px;
    padding-left: 104px;
    padding-right: 60px;
  }
}
</style>
