import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/NewsView.vue"),
  },
  {
    path: "/news/details/:id",
    name: "newsDetails",
    component: () => import("../views/NewsDetailsView.vue"),
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("../views/ActivityView.vue"),
  },
  {
    path: "/activity/details/:id",
    name: "activityDetails",
    component: () => import("../views/ActivityDetailsView.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/SearchView.vue"),
  },
  {
    path: "/cooperative",
    name: "cooperative",
    component: () => import("../views/CooperativeView.vue"),
  },
  {
    path: "/computing",
    name: "computing",
    component: () => import("../views/ComputingView.vue"),
  },
  {
    path: "/resources",
    name: "resources",
    component: () => import("../views/ResourcesView.vue"),
  },
  {
    path: "/training",
    name: "training ",
    component: () => import("../views/TrainingView.vue"),
  },
  {
    path: "/laboratory",
    name: "laboratory ",
    component: () => import("../views/LaboratoryView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
];

if (process.env.NODE_ENV !== "production") {
  routes.push({
    path: "/monitor",
    name: "monitor",
    component: () => import("../views/MonitorView.vue"),
  });
}

const router = new VueRouter({
  routes,
});

export default router;
