<template>
  <div>
    <div class="footer">
      <div class="footer-title"></div>
      <div class="footer-text">汇聚全球顶尖科技，助力中国产业数字化转型。</div>
      <div class="footer-line"></div>
      <div class="footer-content">
        <div class="footer-content-first">
          <div class="footer-content-first-t">网站导航</div>
          <div class="footer-content-first-l"></div>
          <div class="footer-content-first-a">
            <div class="footer-content-first-a-l left">
              <div @click="$router.push('/')">
                <a href="#">首页</a>
              </div>
              <div @click="$router.push('/computing')">
                <a href="#">北京AI创新赋能中心</a>
              </div>
              <div @click="$router.push('/news')">
                <a href="#">公告与新闻</a>
              </div>
            </div>
            <div class="footer-content-first-a-l left">
              <div @click="$router.push('/about')">
                <a href="#">关于我们</a>
              </div>
              <div @click="$router.push('/activity')">
                <a href="#">活动报名</a>
              </div>
              <div @click="$router.push('/contact')">
                <a href="#">联系我们</a>
              </div>
            </div>
            <!-- <div class="footer-content-first-a-l">
              <div @click="$router.push('/cooperative')">
                <a href="#">合作伙伴</a>
              </div>
            </div> -->
          </div>
        </div>
        <div class="footer-content-first">
          <div class="footer-content-first-t">联系我们</div>
          <div class="footer-content-first-l"></div>
          <div class="footer-content-first-a spe connect">
            <div class="footer-content-first-a-l left">
              <div class="footer-content-first-a-f">
                <img
                  src="https://img.ebtech.com/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x.png"
                  alt=""
                  style="width: 18px; height: 21px"
                />
                <span> 北京市财富金融中心 53层 </span>
              </div>
              <div class="footer-content-first-a-f">
                <img
                  src="https://img.ebtech.com/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20%281%29.png"
                  alt=""
                  style="width: 20px; height: 17px"
                />
                <span> info@ebtech.com </span>
              </div>
              <div class="footer-content-first-a-f">
                <img
                  src="https://img.ebtech.com/%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20%281%29.png"
                  alt=""
                  style="width: 20px; height: 20px"
                />
                <div>
                  <span> +86-10-85951008 </span>
                </div>
              </div>
              <div class="footer-content-first-a-f">
                <img
                  src="https://img.ebtech.com/service.png"
                  alt=""
                  style="width: 20px; height: 20px"
                />
                <div>
                  <span>算力服务咨询:</span>
                </div>
              </div>
              <div style="margin-left: 30px; margin-top: -15px">
                enterprise_support@ebtech.com
              </div>
            </div>
          </div>
        </div>
        <div class="footer-content-first">
          <div class="footer-content-first-t">关注我们</div>
          <div class="footer-content-first-l spe"></div>
          <div class="footer-content-first-a spe">
            <div class="footer-content-first-a-l">
              <img
                class="footer-content-first-a-l-img"
                src="https://img.ebtech.com/%E5%9B%BE%E5%B1%82%2010%402x.png"
                alt=""
              />
              <span>官方公众号</span>
            </div>
            <div class="footer-content-first-a-l">
              <!-- <img
                src="@/assets/图层 631@2x.png"
                alt=""
                style="width: 105px; height: 105px; margin-bottom: 20px"
              />
              <div>微信官方客服</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-foot">
      <a
        class="footer-foot-text"
        href="https://beian.miit.gov.cn"
        target="_blank"
        >Copyright@2023 京ICP备2023002581号</a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "FootView",
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer {
  height: 557px;
  background: url("https://img.ebtech.com/%E5%BA%95%E9%83%A8.jpg") center;
  background-blend-mode: multiply;
  background-size: cover;
  padding-top: 46px;
}
.footer-title {
  width: 147px;
  height: 58px;
  background: url("https://img.ebtech.com/%E7%BB%84%2058%402x.png");
  background-size: cover;
  margin: 0 auto;
}
.footer-text {
  color: #fefefe;
  font-size: 16px;
  margin: 22px 0 35px;
}
.footer-line {
  height: 5px;
  background: #fff;
  opacity: 0.7;
}
.footer-content {
  display: flex;
  justify-content: space-around;
  margin-top: 64px;
  text-align: left;
}
.footer-content-first-t {
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}
.footer-content-first-l {
  width: 386px;
  height: 3px;
  background: #fff;
  margin: 33px 0 40px;
}
.footer-content-first-l.spe {
  margin-bottom: 20px;
}
.footer-content-first-a {
  display: flex;
  height: 125px;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.footer-content-first-a.connect {
  height: 150px;
}
.spe {
  cursor: default;
}
.footer-content-first-a-l {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-content-first-a-l.left {
  margin-right: 54px;
}
.footer-content-first-a a {
  color: #fff;
  text-decoration: none;
}
.footer-content-first-a-f {
  display: flex;
  align-items: center;
}
.footer-content-first-a-l img {
  margin-right: 10px;
}
.footer-content-first-a-l-img {
  width: 105px;
  height: 105px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.footer-foot {
  height: 52px;
  background: #000000;
}
.footer-foot-text {
  color: #bfbfbf;
  font-size: 14px;
  line-height: 52px;
}

@media screen and (min-width: 951px) and (max-width: 1420px) {
  .footer-content-first-l {
    width: 29vw;
  }
  .footer-content-first-a-l.left {
    margin-right: 20px;
  }
}
@media screen and (min-width: 0px) and (max-width: 951px) {
  .footer {
    height: 100%;
  }
  .footer-content {
    display: block;
  }
  .footer-content-first {
    width: 90%;
    margin: 0 auto;
  }
  .footer-content-first-a-l.left {
    margin-right: 35px;
  }
  .footer-content-first-l {
    margin: 0 0 20px;
    width: 100%;
    height: 1px;
  }
  .footer-content-first-a {
    height: 90px;
    margin-left: 10px;
    font-size: 14px;
  }
  .footer-content-first-a.connect {
    height: 150px;
  }
  .footer-content-first-t {
    margin: 20px 0 0 10px;
    font-size: 18px;
  }
  .footer-content-first-a-l-img {
    width: 70px;
    height: 70px;
    margin-bottom: 0;
  }
}
</style>
