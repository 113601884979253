<template>
  <div class="xtx-carousel">
    <swiper ref="mySwiper" :options="swiperOption" class="swiper-container">
      <swiper-Slide
        class="swiper-slide"
        v-for="(item, index) in swiperList"
        :key="index"
      >
        <div
          v-if="item.img"
          @mouseover="topSwiper"
          @mouseleave="startSwiper"
          class="container-news-carousel-item"
          :style="{ backgroundImage: 'url(' + item.img + ')' }"
        >
          <div class="container-news-carousel-item-f">
            <div class="container-news-carousel-item-e">
              {{ item.newsTitleEn }}
            </div>
            <div class="container-news-carousel-item-title">
              {{ item.newsTitleZh }}
            </div>
            <div class="container-news-carousel-item-text">
              {{ item.newsDesc }}
            </div>
          </div>
          <div class="container-news-carousel-item-s"></div>
          <div class="container-news-carousel-item-line"></div>
        </div>
      </swiper-Slide>
    </swiper>
  </div>
</template>

<script>
import axios from "axios";
import swiper from "swiper";
let vm = null;
export default {
  name: "XtxCarousel",
  data() {
    return {
      swiperList: [
        {
          imgUrl:
            "https://img14.360buyimg.com/pop/s590x470_jfs/t1/107362/20/24177/90602/6233decdE5627d944/b7194e538e3c8c7a.jpg",
        },
        {
          imgUrl:
            "https://img12.360buyimg.com/pop/s590x470_jfs/t1/204028/36/25480/101429/62f507a7Ec62b0cd3/db5984ae24ce0212.jpg",
        },
        {
          imgUrl:
            "https://imgcps.jd.com/ling4/100022552927/5Lqs6YCJ5aW96LSn/5L2g5YC85b6X5oul5pyJ/p-5f3a47329785549f6bc7a6e6/075268d0/cr/s/q.jpg",
        },
        // {
        //   imgUrl:
        //     "https://imgcps.jd.com/ling4/100026667910/5Lqs6YCJ5aW96LSn/5L2g5YC85b6X5oul5pyJ/p-5f3a47329785549f6bc7a6e3/d7b3695b/cr/s/q.jpg",
        // },
        // {
        //   imgUrl:
        //     "https://img30.360buyimg.com/pop/s590x470_jfs/t1/201288/25/23710/64955/62f070fdE95cb4f47/22b916d938a43bb5.jpg",
        // },
      ],
      swiperOption: {
        loop: true, // 自动播放
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
          // pauseOnMouseEnter: true,
        },
        speed: 9000, // 播放速度
        direction: "horizontal", //默认horizontal, 水平轮播
        slidesPerView: 3, //默认1, 同时显示的slides数量,auto 代表根据轮播图的宽度排列
        spaceBetween: 0, //轮播图之间的间距
        // freeMode: false,
        freeModeMomentum: false,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        on: {
          click: function () {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            vm.handleClickSlide(this);
          },
        },
      },
    };
  },
  mounted() {
    axios
      .get(`/portal/news/list`, {
        params: {
          currentPage: 1,
          size: 10,
        },
      })
      .then((res) => {
        this.swiperList = res.data.data.list.slice(0, 5);
      });
  },
  created() {
    vm = this;
  },
  methods: {
    handleClickSlide(vm) {
      let idx = vm.clickedIndex - vm.activeIndex + vm.realIndex;
      let index = idx === this.swiperList.length ? 0 : idx;
      if (idx > this.swiperList.length) {
        index = idx - this.swiperList.length;
      }
      // 我得到的index就是点击的item在实际数组中的下标index
      this.$router.push("/news/details/" + this.swiperList[index].id);

      // 后面可以根据得到的index判断跳到每个对应的路由this.$router.push({path: `/single`});
    },
    topSwiper() {
      // 存放鼠标悬浮时的transform属性
      // this.nextTransForm =
      //   this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transform;
      // // 轮播图原本应移动到的位置
      // let nextTransPosition =
      //   -1 *
      //   parseInt(this.nextTransForm.split("px")[0].split("translate3d(")[1]);
      // // // 鼠标悬浮时时轮播图位置
      // let nowTransPosition =
      //   -1 *
      //   parseInt(
      //     window
      //       .getComputedStyle(this.$refs.mySwiper.$swiper.$wrapperEl[0], false)
      //       ["transform"].split("1, ")[2]
      //       .split(",")[0]
      //   );

      // // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
      // let nowTransForm = window.getComputedStyle(
      //   this.$refs.mySwiper.$swiper.$wrapperEl[0],
      //   false
      // )["transform"];

      // // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（150是我自定义的每个slide的宽度）
      // this.nextTime = 4000 * ((nextTransPosition - nowTransPosition) / 150);

      // // 改变行内transform属性
      // this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transform = nowTransForm;
      // this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transitionDuration =
      //   "0ms";
      this.$refs.mySwiper.$swiper.autoplay.stop();
    },
    startSwiper() {
      // 恢复原样
      // this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transform =
      //   this.nextTransForm;
      // this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transitionDuration =
      //   this.nextTime + "ms";
      this.$refs.mySwiper.$swiper.autoplay.start();
    },
  },
};
</script>
<style scoped lang="less">
.swiper-slide {
  width: 434px;
  height: 312px;
}
.container-news-carousel-item {
  position: relative;
  width: 434px;
  height: 270px;
  background: rgba(0, 0, 0, 0.2);
  background-blend-mode: multiply;
  background-size: cover;
  text-align: left;
  color: #fefefe;
  cursor: pointer;
}
.container-news-carousel-item:hover .container-news-carousel-item-text {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.container-news-carousel-item:hover .container-news-carousel-item-s {
  width: 434px;
  opacity: 0.9;
}
.container-news-carousel-item:hover .container-news-carousel-item-line {
  width: 434px;
}
.container-news-carousel-item-f {
  position: absolute;
  left: 16px;
  bottom: 16px;
  padding-right: 16px;
  z-index: 99;
}
.container-news-carousel-item-e {
  font-size: 14px;
}
.container-news-carousel-item-title {
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.container-news-carousel-item-text {
  display: none;
  font-weight: 300;
  font-size: 12px;
  width: 375px;
}
.container-news-carousel-item-s {
  width: 0px;
  height: 270px;
  background: #511f5a;
  opacity: 0.9;
  transition: width 0.5s;
}
.container-news-carousel-item-line {
  position: absolute;
  bottom: -30px;
  width: 0px;
  height: 2px;
  background: #511f5a;
  transition: width 0.5s;
}

/*调整成匀速*/
::v-deep .swiper-container .swiper-wrapper {
  -webkit-transition-timing-function: linear; /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
@media screen and (min-width: 951px) and (max-width: 1340px) {
.xtx-carousel{ width: 1310px;}

}
@media screen and (min-width: 0px) and (max-width: 950px) {
  .swiper-container {
    width: 950px;
    overflow: hidden;
  }
  .swiper-slide {
    width: 316px;
    height: 205px;
  }
  .container-news-carousel-item {
    width: 316px;
    height: 205px;
  }
  .container-news-carousel-item-title {
    font-size: 14px;

  }

  .container-news-carousel-item-text {
    width: 100%;
  }
}
</style>
